<template>
  <div class="home-page">
    <VSpinner v-if="$store.getters.isLoading"></VSpinner>
    <div v-if="localeValid" class="personalised-banner">
      <div class="img-block">
        <img :src="$t(requiredKeys['banner-url'])" alt="Banner Image" />
      </div>
      <div class="text-block-default">
        <h2>
          {{ $t(requiredKeys['banner-header'], { userNameAndSurname: userNameAndSurname }) }}
        </h2>
        <div class="centered-content">
          <p>{{ $t(requiredKeys['banner-text']) }}</p>
          <a :href="$t(requiredKeys['button-url'])">
            <button class="save-info-button text-nowrap">
              <a style="color: var(--White); text-decoration: none">
                {{ $t(requiredKeys['button-text']) }}
              </a>
            </button>
          </a>
        </div>
      </div>
    </div>
    <div v-else class="welcome-block">
      <div class="img-block"><img src="@/assets/cubeImage.png" alt="Cube Image" /></div>
      <div class="text-block-default">
        <h2>
          {{ $t("homepage_banner_header", { userNameAndSurname: userNameAndSurname }) }}
        </h2>
        <div class="centered-content">
          <p>{{ $t("homepage_banner_mainText") }}</p>
          <button class="save-info-button text-nowrap" @click="$router.push('/profile')">
            <a style="color: var(--White); text-decoration: none">
              {{ $t("homepage_banner_button_text") }}
            </a>
          </button>
        </div>
      </div>
    </div>
    <div>
      <ProductList />
    </div>
  </div>
</template>

<script>
import ProductList from "@/components/ProductList.vue";
import VSpinner from "@/components/UI/VSpinner.vue";

export default {
  name: "HomePage",
  components: {
    ProductList,
    VSpinner,
  },
  data() {
    return {
      localeValid: false,
      cuurentLocale: "",
    };
  },
  methods: {
    checkWorkingEnvironment(newLocale) {
      const keys = Object.values(this.requiredKeys);
      if (newLocale) {
        this.currentLocale = newLocale
      } else {
        this.currentLocale = this.$i18n.locale;
      }
      const allExist = keys.every(key => {
        // Check if the translation exists and is not an empty string
        return this.$te(key, this.currentLocale) && this.$t(key).trim() !== '';
      });
      console.log(allExist);
      this.localeValid = allExist;
    }
  },
  computed: {
    userNameAndSurname() {
      const { first_name, last_name } = this.$store.state.userData;
      if (this.$store.state.activeLocale === 'ja') {
        return `${last_name} ${first_name}`;
      } else {
        return `${first_name} ${last_name}`;
      }
    },
    requiredKeys() {
      const working_environment = this.$store.state.userData.working_environment;
      return {
        'banner-header': `${working_environment}-banner-header`,
        'banner-text': `${working_environment}-banner-text`,
        'banner-url': `${working_environment}-banner-url`,
        'button-text': `${working_environment}-button-text`,
        'button-url': `${working_environment}-button-url`
      };
    }
  },
  watch: {
    '$store.state.activeLocale': {
      handler(newLocale) {
        this.checkWorkingEnvironment(newLocale);
      },
      immediate: true,
    },
  },
  created() {
    this.checkWorkingEnvironment();
  }
};
</script>

<style scoped>
/* Your styles here */
</style>


<style lang="scss" scoped>
.home-page {
  background-color: var(--bg-grey);
  margin: 0px;
  padding: 0px;
  padding-bottom: 70px;
  height: calc(100vh - var(--UpperMenuHeight));
  overflow-y: scroll;
}

.welcome-block,
.personalised-banner {
  display: flex;
  align-items: center;
  height: 420px;
  color: var(--White);
  background-color: var(--Black);

  .img-block {
    flex-grow: 1;
    display: flex;
    justify-content: center;

    img {
      align-self: center;
      //filter: invert(100%);
    }
  }

  .text-block-default {
    flex-grow: 2;
    margin-right: 70px;

    p {
      max-width: 800px;
    }
  }
}

.img-block {
  img {
    width: 420px;
    height: auto;
  }
}

@media (max-width: 1024px) {
  .img-block {
    img {
      width: 232px;
    }
  }
}

@media (max-width: 758px) {
  .img-block {
    margin: 0px;

    img {
      width: 340px;
    }
  }

  .welcome-block {
    flex-direction: column;
    height: auto;

    .text-block-default {
      margin: 0px;

      h2 {
        margin: 10px;
        font-size: 2.125rem;
        text-align: center;
        margin-bottom: 40px;
        font-weight: 700;
      }
    }

    .centered-content {
      display: none;
    }
  }
}
</style>
