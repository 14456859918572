import { createRouter, createWebHistory } from "vue-router";
import HomePage from "@/views/HomePage.vue";
import RoomsPage from "@/views/RoomsPage.vue";
import SoftwarePage from "@/views/SoftwarePage.vue";
import ServicePage from "@/views/ServicePage.vue";
import NewServicePage from "@/views/NewServicePage.vue";
import ErrorPage from "@/views/ErrorPage.vue";
import ProductPage from "@/views/ProductPage.vue";
import ProductRegistration from "@/components/ProductRegistration.vue";
import profilePage from "@/views/ProfilePage.vue";
import RoomInformation from "@/components/RoomInformation.vue";
import Subscriptions from "@/views/SubscriptionsPage.vue";
import Support from "@/views/SupportPage.vue";
import QRregPage from "@/views/QRregPage.vue";
import store from "@/store";
import KeyCloakService from "@/security/KeyCloakService";
import NotFound from "@/views/NotFoundPage.vue";
import SmartIP from "@/views/SmartIPPage.vue";
import GradeReports from "@/views/GradeReports.vue";
import BetaSoftwarePage from "@/views/BetaSoftwarePage.vue";
import PaymentsPage from "@/views/PaymentsPage.vue";
import WarrantyCheckPage from "@/views/WarrantyCheckPage.vue";

const routes = [
  {
    path: "/",
    component: HomePage,
    name: "Home",
    meta: { requiresAuth: true },
  },
  {
    path: "/rooms",
    component: RoomsPage,
    name: "Rooms",
    meta: { requiresAuth: true },
  },
  {
    path: "/software",
    component: SoftwarePage,
    name: "Software",
    meta: { requiresAuth: true },
  },
  {
    path: "/services",
    component: ServicePage,
    name: "Services",
    meta: { requiresAuth: true },
  },
  {
    path: "/new-services",
    component: NewServicePage,
    name: "NewServices",
    meta: { requiresAuth: true },
  },
  {
    path: "/error",
    component: ErrorPage,
    name: "Error",
    meta: { requiresAuth: true },
  },
  {
    path: "/products",
    component: ProductPage,
    name: "Products",
    meta: { requiresAuth: true },
  },
  {
    path: "/product-registration",
    component: ProductRegistration,
    name: "ProductRegistration",
    meta: { requiresAuth: true },
  },
  {
    path: "/profile",
    component: profilePage,
    name: "profilePage",
    meta: { requiresAuth: true },
  },
  {
    path: "/location/:locationId/room/:roomId", // :id is a dynamic segment
    name: "room",
    component: RoomInformation,
    meta: { requiresAuth: true },
  },
  {
    path: "/subscriptions",
    component: Subscriptions,
    name: "SubscriptionsPage",
    meta: { requiresAuth: true },
  },
  {
    path: "/support",
    component: Support,
    name: "SupportPage",
    meta: { requiresAuth: true },
  },
  {
    path: "/register-products/:serialNumbers?",
    component: QRregPage,
    name: "QRregPage",
    props: true,
    meta: { requiresAuth: false },
  },
  {
    path: "/smart-ip",
    component: SmartIP,
    name: "SmartIpPage",
    meta: { requiresAuth: true },
  },
  {
    path: "/grade",
    component: GradeReports,
    name: "GradeReports",
    meta: { requiresAuth: true },
  },
  {
    path: "/beta-software",
    component: BetaSoftwarePage,
    name: "BetaSoftware",
    meta: { requiresAuth: true },
  },
  {
    path: "/payments",
    component: PaymentsPage,
    name: "PaymentsPage",
    meta: { requiresAuth: true },
  },
  {
    path: "/warranty-check",
    component: WarrantyCheckPage,
    name: "WarrantyPage",
    meta: { requiresAuth: true },
  },
  {
    path: "/not-found",
    component: NotFound,
    name: "NotFound",
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/not-found",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const isAuthenticated = KeyCloakService.isAuthenticated();

  if (requiresAuth && !isAuthenticated) {
    // If the route requires authentication and the user is not authenticated
    // console.log("Authentication: none");
    store.commit("setLandingPage", true);
    // next("/"); // Redirect to home or login page
  } else if (!requiresAuth && isAuthenticated) {
    // If the route doesn't require authentication and the user is authenticated
    // console.log("Authentication: yes 1");
    store.commit("setLandingPage", false);
    next(); // Allow the navigation
  } else {
    // console.log(store.state.accessTokenInfo.realm_access?.roles);
    //For Beta Software
    if (to.path === "/beta-software") {
      if (
        !store.state.accessTokenInfo.realm_access?.roles.includes(
          "beta-test-user"
        )
      ) {
        next("/not-found");
      }
    }
    //For Warranty Check
    if (to.path === "/warranty-check") {
      if (
        !store.state.accessTokenInfo.realm_access?.roles.includes(
          "support-access-warranty-read"
        )
      ) {
        next("/not-found");
      }
    }
    // For all other cases
    store.commit("setLandingPage", false);
    next(); // Allow the navigation
  }
});

export default router;
